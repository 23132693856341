*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  direction: rtl;
  overflow-x: hidden;
}

body,
html,
*:not(i) {
  font-family: Heebo, sans-serif !important;
}

:root {
  --primary-purple: #875eff;
  --PURPLE_COLOR: #010a7a;
  --WHITE_COLOR: #fff;

  --primary-blue: #010a7a;
  --primary-purple: #875eff;
}

/* WebKit Scrollbar Styles */
::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #875eff; /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Set the border radius of the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the scrollbar track */
}

/* Firefox Scrollbar Styles */
/* Note: These styles may not work in all versions of Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #875eff #f1f1f1; /* Set the color of the scrollbar thumb and track */
}

#root {
  height: 100%;
}

.main {
  width: 100%;
  height: 100%;
  padding: 2rem 4rem;
}

.css-1fdsijx-ValueContainer {
  padding: 0 8px !important;
}

.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.inline-block {
  display: inline-block;
}
.v-align-middle {
  vertical-align: middle;
}
.w-full {
  width: 100%;
}
.w-1-2 {
  width: 50%;
}
.h-full {
  height: 100%;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.transition-all {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.cusror-pointer {
  cursor: pointer;
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
